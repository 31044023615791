import React, { useState, ReactNode } from 'react'
import { Field, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useQueryClient } from '@tanstack/react-query'
import { userKeys } from '../../queries/user/keyFactory'
import { useUserContext } from '../../context/user/UserProvider'
import { Form } from 'react-bootstrap'

interface IDatePickerProps {
  name: string
  label: string
  hint?: string | ReactNode
  flush?: boolean
  clearable?: boolean
}

const RwDatePicker: React.FC<IDatePickerProps> = ({ ...props }) => {
  const { setFieldValue } = useFormikContext()
  const queryClient = useQueryClient()
  const { isDarkMode } = useUserContext()

  interface PickupDateData {
    [key: string]: {
      id: number
      customer_type: number
      pickup_date: string
      enabled: number
    }
  }

  const handleDateChange = (val: any, fieldName: any) => {
    if (val) {
      const date = new Date(val)

      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const day = date.getDate().toString().padStart(2, '0')
      const year = date.getFullYear().toString()
      const formattedDate = `${month}/${day}/${year}`

      setFieldValue(fieldName, formattedDate)
    } else {
      setFieldValue(fieldName, '')
    }
  }

  const marginClass = props.flush ? '' : 'mb-4'

  return (
    <Field name={props.name}>
      {({ field }: { field: { name: string; value: string | Date } }) => (
        <div className={`custom-date-picker-width ${marginClass}`}>
          <DatePicker
            {...field}
            {...props}
            className="custom-date-picker"
            // wrapperClassName="w-100"
            value={field.value ? field.value.toString() : undefined}
            selected={field.value ? new Date(field.value) : null}
            autoComplete="off"
            placeholderText={props.label}
            onChange={(val) => {
              handleDateChange(val, field.name)
            }}
            disabledKeyboardNavigation // hides the dumb highlighting of selected date number in other months
            onKeyDown={(e) => {
              e.preventDefault()
            }}
            isClearable={props.clearable ?? false}
            customInput={
              <input
                className={`w-75 rounded-1 ${isDarkMode ? 'dm-body-bg' : ''}`}
                style={{
                  border: '1px solid lightgrey',
                  height: '38px',
                  fontSize: '14px',
                }}
              />
            }
          />
          {typeof props.hint === 'string' && props.hint.length > 0 && (
            <Form.Text className="text-muted">{props.hint}</Form.Text>
          )}
        </div>
      )}
    </Field>
  )
}
export default RwDatePicker
